
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import { config } from "@/views/agenda-virtual/ConfigEvents";

export default defineComponent({
  name: "view-agendamento-modal",
  components: {},

  props: {
		eventInfo: Object,
    showStatusControl: {
      type: Boolean,
      default: false
    },
    showEntregaControl: {
      type: Boolean,
      default: false
    },
    editable: Boolean,
    id: String,
  },

  emits: ["addTask", "editTask", "success"],

  setup(props, { emit }) {
    const store = useStore();
    const { showConfirmAlert, showTimeAlert } = useAlert();

    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const loadingBtn = ref(false);

    const targetData:any = ref({
      codServico: "",
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      chassi: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      endWithout: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
      tempoAdicional: "",
      tempoAdicionalSelected: "",
      codTipoServico: "",
    });

    function closeModal() {
      const modal = document.getElementById(`${props.id}`);
      hideModal(modal);
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    watch(() => props.eventInfo, () => {
      const eventInfo:any = props.eventInfo;

      const auxServicosAgendados = eventInfo.servicosAgendados && eventInfo.servicosAgendados.length > 0 ? eventInfo.servicosAgendados.filter(servico => servico.codTipoServico < 9).map(servico => {
        const eventConfig = config.events[servico.codTipoServico - 1];
        const start = moment(servico.dataInicioServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
        const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(end.diff(start)).asMinutes();

        let bgColor = "";
        let headerTextColor = "#fff";
        if(store.getters.modeloAgendaName == "CampV1"){
          const defaultCor = store.getters.coresAgenda[store.getters.consultoresList?.length] ?? '#d3d3d3';
          bgColor = store.getters.consultoresList.find(consultor => removeAcentos(consultor.intConsultor, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
          if(!bgColor){
            bgColor = store.getters.consultoresList.find(consultor => removeAcentos(consultor.nome, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
          }
          bgColor = bgColor ?? defaultCor;
        } else {
          bgColor = eventConfig.extendedProps.bgColor,
          headerTextColor = eventConfig.extendedProps.headerTextColor
        }

        const newEvent = {
          id: servico.codServico,
          resourceId: `${servico.codTecnico.toString()}`,
          title: eventConfig.title,
          start: start.format("YYYY-MM-DD HH:mm:ss"),
          end: end.format("YYYY-MM-DD HH:mm:ss"),
          extendedProps: {
            duration,
            status: servico.status,
            showDuration: eventConfig.extendedProps.showDuration,
            aguarda: servico.aguarda == "1" ? true : false,
            hasContent: eventConfig.extendedProps.hasContent,
            hasFooter: eventConfig.extendedProps.hasFooter,
            cliente: trataNome(servico.cliente),
            consultor: trataNome(servico.consultorAgendado),
            recepcao: servico.dataRecepcao ? servico.dataRecepcao.replace(" ", "T") : "", 
            entrega: servico.dataEntrega ? servico.dataEntrega.replace(" ", "T") : "",
            placa: servico.placa,
            bgColor,
            headerTextColor,
            allInfo: servico,
          }
        };

        return newEvent;
      }) : [];

      if (eventInfo.tipoCod == 2) {
        const auxData = moment(eventInfo.osdataprometidaentrega, "YYYY-MM-DD HH:mm:ss");
        
        const info = {
          aguarda: "",
          codAgendamento: eventInfo.idagendamento,
          detalhes: "",
          cliente: eventInfo.cliente,
          placa: eventInfo.placa,
          chassi: eventInfo.chassi,
          statusName: "",
          taskDate: auxData.format("DD/MM/YY"),
          horaAgendada: auxData.format("HH:mm"),
          consultorAgendado: eventInfo.consultornome ?? "",
          marca: eventInfo.marca,
          modelo: eventInfo.modelo,
          osAberta: eventInfo.osnumero,
          servicosAgendados: []
        }
        targetData.value = info; 
      } else {
        const info = {
          aguarda: eventInfo.aguarda,
          codAgendamento: eventInfo.codAgendamento,
          detalhes: eventInfo.obs,
          cliente: eventInfo.cliente,
          placa: eventInfo.placa,
          chassi: eventInfo.chassi,
          statusName: eventInfo.statusName,
          taskDate: eventInfo.dataagendamento,
          horaAgendada: eventInfo.horaagendada,
          consultorAgendado: eventInfo.consultor_agendado ?? "",
          marca: eventInfo.marca,
          modelo: eventInfo.modelo,
          osAberta: eventInfo.osaberta,
          servicosAgendados: [...auxServicosAgendados]
        };
        targetData.value = info;
      }
    });

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]} ${arrayNomes[arrayNomes.length - 1].substring(0, 1)}.` 

      return nomeTratado;
    }


    return {
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      initialTaskEndHour,
      initialHourEntrega,
      ptBr,
      closeModal,
      loadingBtn,
      modeloAgendaName: computed(() => store.getters.modeloAgendaName),
    };
  },
});
